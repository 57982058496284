import {
	Text,
	AsyncSelect,
	Badge,
	Box,
	Button,
	Icons,
	Table,
	Tooltip,
	Stack,
	Inline,
	Dropdown,
	joinAttributes,
	Ping,
	RelativeTime,
	TabContent,
	TabItem,
	Tabs,
	TabsList,
	Time,
	TimeDuration,
	useBreakpoints,
	Money,
} from "@sembark-travel/ui/base"
import {
	utcTimestampToLocalDate,
	utcTimestampToLocalDateString,
	dateToQuery,
	dateToUTCString,
	parseDateFromQuery,
	isAfter,
	duration,
	fromNow,
	isBefore,
	getDiff,
	startOf,
	endOf,
	localOrUtcTimestampToLocalDate,
} from "@sembark-travel/datetime-utils"
import { withOrdinalSuffix } from "@sembark-travel/number-utils"
import {
	NavLink,
	ButtonLink,
	Link,
	useLocationQuery,
} from "@sembark-travel/ui/router"
import {
	useSearch,
	TSearchParams,
	Search,
	ListView,
	areAdvancedFiltersAppliedDefault,
	TSortOptions,
} from "@sembark-travel/ui/list"
import { useXHR } from "@sembark-travel/xhr"
import pluralize from "pluralize"
import React, { Fragment, useEffect } from "react"
import { $PropertyType } from "utility-types"
import { PERMISSIONS, useCheckPermissions, useHasFeatureFlag } from "../Auth"
import { AddCommentInDialog } from "../Comments"
import config from "../config"
import { Email, PhoneNumber } from "../Contacts"
import { generatePath } from "../router-utils"
import { EditTagsInDialog, SelectTags, type ITag } from "../Tags"
import {
	SelectTripDestination,
	type TTripDestination,
} from "../TripDestinations"
import { SelectTripSources } from "../TripSources"
import { type ITripSource } from "../TripSources"
import { type IUser } from "../Users"
import { RevertTripCancellationInDialog } from "./CancelTrip"
import { type ITrip, tripListXHR } from "./store"
import {
	SelectField,
	DateRangePickerField,
	isTruthy,
	SwitchInputField,
} from "@sembark-travel/ui/form"
import { collect } from "../utils"

const sortOptions: TSortOptions = [
	{
		label: "Oldest Follow-Up First",
		name: "last_follow_up_at",
		type: "date",
		order: "asc",
		removeOrderSuffix: true,
	},
	{
		label: "Latest Follow-Up First",
		name: "last_follow_up_at",
		type: "date",
		order: "desc",
		removeOrderSuffix: true,
	},
	{
		label: "Start Date",
		name: "start_date",
		type: "date",
		order: "asc",
	},
	{
		label: "Latest Created First",
		name: "created_at",
		type: "date",
		order: "desc",
		removeOrderSuffix: true,
	},
]

type TTripStatus =
	| "new"
	| "in_progress"
	| "on_hold"
	| "converted"
	| "on_trip"
	| "past"
	| "canceled"
	| "dropped"
	| "all"

export interface IFilters {
	q?: string
	limit?: number
	tags?: Array<ITag>
	owners?: Array<IUser>
	page?: number
	sources?: Array<ITripSource>
	destinations?: Array<TTripDestination>
	live_start?: Date
	live_end?: Date
	created_after?: Date
	created_before?: Date
	starts_after?: Date
	starts_before?: Date
	ends_after?: Date
	ends_before?: Date
	status?: TTripStatus
	sort?: string
	has_due_payments?: 0 | 1
}

const resetParams = (params: IFilters) => {
	return {
		q: "",
		tags: [],
		owners: [],
		sources: [],
		destinations: [],
		page: 1,
		live_start: undefined,
		live_end: undefined,
		created_after: undefined,
		created_before: undefined,
		status: params.status,
		starts_after: undefined,
		starts_before: undefined,
		has_due_payments: undefined,
	}
}
export interface IFiltersInLocationQuery extends TSearchParams {
	tags?: string[]
	sort?: string
	owners?: Array<string>
	sources?: string[]
	destinations?: string[]
	page?: number
	hnb?: number | null
	ls?: string
	le?: string
	ca?: string
	cb?: string
	sa?: string
	sb?: string
	ea?: string
	eb?: string
	status?: TTripStatus
	hdp?: 1
}

function paramsToLocationQuery(params: IFilters): IFiltersInLocationQuery {
	const {
		q,
		page,
		tags = [],
		owners = [],
		sources,
		live_start,
		live_end,
		created_after,
		created_before,
		status,
		destinations,
		sort,
		starts_after,
		starts_before,
		ends_after,
		ends_before,
		has_due_payments,
	} = params
	const filters: IFiltersInLocationQuery = { status: status }
	if (q) {
		filters.q = q
	}
	if (page) {
		filters.page = page
	}
	if (tags && tags.length) {
		filters.tags = tags.map((s) => `${s.id}_${s.name}`)
	}
	if (owners && owners.length) {
		filters.owners = owners.map((s) => `${s.id}_${s.name}`)
	}
	if (sources?.length) {
		filters.sources = sources.map((s) => `${s.id}_${s.name}`)
	}
	if (destinations?.length) {
		filters.destinations = destinations.map((s) => `${s.id}_${s.name}`)
	}
	if (live_start) {
		filters.ls = dateToQuery(live_start)
	}
	if (live_end) {
		filters.le = dateToQuery(live_end)
	}
	if (created_after) {
		filters.ca = dateToQuery(created_after)
	}
	if (created_before) {
		filters.cb = dateToQuery(created_before)
	}
	if (starts_after) {
		filters.sa = dateToQuery(starts_after)
	}
	if (starts_before) {
		filters.sb = dateToQuery(starts_before)
	}
	if (ends_after) {
		filters.ea = dateToQuery(ends_after)
	}
	if (ends_before) {
		filters.eb = dateToQuery(ends_before)
	}
	if (has_due_payments) {
		filters.hdp = isTruthy(has_due_payments) ? 1 : undefined
	}
	if (sort) {
		filters.sort = sort
	}
	return filters
}

function locationQueryToParams(query: IFiltersInLocationQuery): IFilters {
	const {
		q,
		page,
		tags = [],
		owners = [],
		sources,
		ls,
		le,
		ca,
		cb,
		status,
		destinations,
		sort,
		sa,
		sb,
		ea,
		eb,
		hdp,
	} = query
	const filters: IFilters = { status: status }
	if (q) {
		filters.q = q
	}
	if (page) {
		filters.page = page
	}
	if (tags && tags.length) {
		filters.tags = tags.map((s: string) => {
			const [id, ...name] = s.split("_")
			return {
				id: isNaN(parseInt(id)) ? id : parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<IFilters, "tags">
	}
	if (owners && owners.length) {
		filters.owners = owners.map((s: string) => {
			const [id, ...name] = s.split("_")
			return {
				id: isNaN(parseInt(id)) ? id : parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<IFilters, "owners">
	}
	if (sources?.length) {
		filters.sources = sources.map((s: string) => {
			const [id, ...name] = s.split("_")
			return {
				id: isNaN(parseInt(id)) ? id : parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<IFilters, "sources">
	}
	if (destinations?.length) {
		filters.destinations = destinations.map((s: string) => {
			const [id, ...name] = s.split("_")
			return {
				id: isNaN(parseInt(id)) ? id : parseInt(id),
				name: name.join("_"),
			}
		}) as unknown as $PropertyType<IFilters, "destinations">
	}
	if (ls) filters.live_start = parseDateFromQuery(ls)
	if (le) filters.live_end = parseDateFromQuery(le)
	if (ca) filters.created_after = parseDateFromQuery(ca)
	if (cb) filters.created_before = parseDateFromQuery(cb)
	if (sa) filters.starts_after = parseDateFromQuery(sa)
	if (sb) filters.starts_before = parseDateFromQuery(sb)
	if (ea) filters.ends_after = parseDateFromQuery(ea)
	if (eb) filters.ends_before = parseDateFromQuery(eb)
	if (hdp && isTruthy(hdp)) filters.has_due_payments = 1
	if (sort) filters.sort = sort
	return filters
}

export function TripsList({
	title = "Trips",
	disableAddition = false,
	canceled = false,
}: {
	title?: string
	disableAddition?: boolean
	canceled?: boolean
}) {
	const [queryFilters, setQueryFilters] = useLocationQuery<
		IFilters,
		IFiltersInLocationQuery
	>({
		toQuery: paramsToLocationQuery,
		fromQuery: locationQueryToParams,
	})
	const { hasPermission } = useCheckPermissions()
	const canViewLeads = hasPermission(PERMISSIONS.VIEW_QUERIES)
	const hasTripHoldingFeature = useHasFeatureFlag("hold_trips")
	const [params, setParams] = useSearch<IFilters>(() => {
		let status = queryFilters.status || "new"
		if (!canViewLeads) {
			const validStatus = (
				(hasTripHoldingFeature ? ["on_hold"] : []) as Array<
					Required<IFilters>["status"]
				>
			).concat(["converted", "on_trip", "dropped", "past"])
			if (validStatus.indexOf(status) === -1) {
				status = validStatus[0]
			}
		}
		return {
			...queryFilters,
			status,
		}
	})
	useEffect(() => {
		setQueryFilters(params)
	}, [params, setQueryFilters])
	const { xs } = useBreakpoints()
	return (
		<Fragment>
			<Search
				title={title}
				placeholder="Search by id, guest, phone numbers..."
				Filters={Filters}
				initialParams={params}
				resetParams={resetParams}
				onSearch={(newParams) => {
					setParams({ ...newParams, page: 1 })
				}}
				areAdvancedFiltersApplied={(filters) => {
					const { status, ...otherParams } = filters
					return areAdvancedFiltersAppliedDefault(otherParams)
				}}
				actions={
					!disableAddition && hasPermission(PERMISSIONS.CREATE_QUERIES) ? (
						<ButtonLink
							to={generatePath("/trips/new")}
							level="primary"
							data-testid="add_new_query"
						>
							Add New Query
						</ButtonLink>
					) : null
				}
			>
				{({ searchParams, setSearchParamValue }) => (
					<Tabs>
						<TabsList>
							{canViewLeads ? (
								<>
									<TabItem
										onClick={() => {
											setSearchParamValue("status", "new")
											setSearchParamValue("sort", undefined)
										}}
										active={
											!searchParams.status || searchParams.status === "new"
										}
									>
										New Query
									</TabItem>
									<TabItem
										onClick={() => {
											setSearchParamValue("status", "in_progress")
											setSearchParamValue("sort", undefined)
										}}
										active={searchParams.status === "in_progress"}
									>
										In Progress
									</TabItem>
								</>
							) : null}
							{hasTripHoldingFeature ? (
								<TabItem
									onClick={() => {
										setSearchParamValue("status", "on_hold")
										setSearchParamValue("sort", undefined)
									}}
									active={searchParams.status === "on_hold"}
								>
									On Hold
								</TabItem>
							) : null}
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "converted")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "converted"}
							>
								Converted
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "on_trip")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "on_trip"}
							>
								On Trip
							</TabItem>
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "past")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "past"}
							>
								Past Trips
							</TabItem>
							{canViewLeads ? (
								<TabItem
									onClick={() => {
										setSearchParamValue("status", "canceled")
										setSearchParamValue("sort", undefined)
									}}
									active={searchParams.status === "canceled"}
								>
									Canceled
								</TabItem>
							) : null}
							<TabItem
								onClick={() => {
									setSearchParamValue("status", "dropped")
									setSearchParamValue("sort", undefined)
								}}
								active={searchParams.status === "dropped"}
							>
								Dropped
							</TabItem>
							{canViewLeads ? (
								<TabItem
									onClick={() => {
										setSearchParamValue("status", "all")
										setSearchParamValue("sort", undefined)
									}}
									active={searchParams.status === "all"}
									title="All"
								>
									All
								</TabItem>
							) : null}
						</TabsList>
						<TabContent>
							<ListView<ITrip, IFilters>
								pageKey={`trips-listing${canceled ? "-canceled" : ""}`}
								params={params}
								sortOptions={
									searchParams.status === "new" ||
									searchParams.status === "in_progress" ||
									searchParams.status === "converted"
										? sortOptions
										: undefined
								}
								fetch={(xhr, params) => {
									const {
										tags = [],
										owners = [],
										page = 1,
										sources = [],
										live_start,
										live_end,
										created_after,
										created_before,
										starts_after,
										starts_before,
										ends_after,
										ends_before,
										destinations = [],
										has_due_payments,
										...otherParams
									} = params
									const filterParams = {
										...otherParams,
										tags: tags.map((t) => t.name),
										owners: owners.map((t) => t.id),
										sources: sources.map((t) => t.id),
										destinations: destinations.map((t) => t.id),
										live_start: live_start
											? dateToUTCString(startOf(live_start, "day"))
											: null,
										live_end: live_end
											? dateToUTCString(endOf(live_end, "day"))
											: null,
										created_after: created_after
											? dateToUTCString(startOf(created_after, "day"))
											: null,
										created_before: created_before
											? dateToUTCString(endOf(created_before, "day"))
											: null,
										starts_after: starts_after
											? dateToUTCString(startOf(starts_after, "day"))
											: null,
										starts_before: starts_before
											? dateToUTCString(endOf(starts_before, "day"))
											: null,
										ends_after: ends_after
											? dateToUTCString(startOf(ends_after, "day"))
											: null,
										ends_before: ends_before
											? dateToUTCString(endOf(ends_before, "day"))
											: null,
										page,
										canceled: canceled ? 1 : 0,
										has_due_payments: isTruthy(has_due_payments)
											? 1
											: undefined,
									}
									return tripListXHR(xhr).getTrips(filterParams)
								}}
								onPageChange={(page, sort) =>
									setParams({ ...params, page, sort })
								}
							>
								{({ items: trips, refresh }) =>
									xs ? (
										<CardsView
											items={trips}
											params={params}
											onChange={refresh}
										/>
									) : params.status === "new" ? (
										<NewQueryTableView
											items={trips}
											params={params}
											onChange={refresh}
										/>
									) : params.status === "in_progress" ? (
										<InProgressQueryTableView
											items={trips}
											params={params}
											onChange={refresh}
										/>
									) : params.status === "on_hold" ? (
										<OnHoldTripTableView
											items={trips}
											params={params}
											onChange={refresh}
										/>
									) : params.status === "converted" ? (
										<ConvertedTripTableView
											items={trips}
											params={params}
											onChange={refresh}
										/>
									) : params.status === "on_trip" ? (
										<LiveTripTableView
											items={trips}
											params={params}
											onChange={refresh}
										/>
									) : params.status === "past" ? (
										<PastTripTableView
											items={trips}
											params={params}
											onChange={refresh}
										/>
									) : params.status === "canceled" ? (
										<CanceledTripsTableView
											items={trips}
											params={params}
											onChange={refresh}
										/>
									) : params.status === "dropped" ? (
										<DroppedTripsTableView
											items={trips}
											params={params}
											onChange={refresh}
										/>
									) : (
										<AllQueryTableView
											items={trips}
											params={params}
											onChange={refresh}
										/>
									)
								}
							</ListView>
						</TabContent>
					</Tabs>
				)}
			</Search>
		</Fragment>
	)
}

function Filters() {
	return (
		<Stack gap="4">
			<SelectField
				name="destinations"
				select={SelectTripDestination}
				label="Destinations"
				fetchOnMount
				multiple
				placeholder="Select destination(s)..."
			/>
			<SelectField
				name="sources"
				select={SelectTripSources}
				label="Trip Sources"
				fetchOnMount
				multiple
				placeholder="Select source(s)..."
			/>
			<SelectField
				name="tags"
				select={SelectTags}
				label="Tags"
				type="trip"
				multiple
				creatable={false}
				placeholder="Search & select tags(s)..."
				fetchOnMount
			/>
			<SelectField
				name="owners"
				select={SelectTripOwners}
				label="Sales and Ops owners"
				multiple
				fetchOnMount
				placeholder="Search & select members(s)..."
			/>
			<DateRangePickerField
				label="Live During"
				fromName="live_start"
				toName="live_end"
			/>
			<DateRangePickerField
				label="Created Between"
				fromName="created_after"
				toName="created_before"
				rangePresets={["Today", "Yesterday", "This Month", "Last Month"]}
			/>
			<DateRangePickerField
				label="Start-Date Between"
				fromName="starts_after"
				toName="starts_before"
				rangePresets={["Today", "Yesterday", "Next 7 Days"]}
			/>
			<DateRangePickerField
				label="End-Date Between"
				fromName="ends_after"
				toName="ends_before"
				rangePresets={["Today", "Yesterday", "Next 7 Days", "Prev 7 Days"]}
			/>
			<SwitchInputField label="Has Due Payments" name="has_due_payments" />
		</Stack>
	)
}

function IDCell({ trip }: { trip: ITrip }) {
	return (
		<Box>
			<Link
				to={`/trips/${trip.id}`}
				anchored
				color="accent"
				fontWeight="semibold"
			>
				<Inline as="span" gap="1" alignItems="center">
					<Box>{trip.id}</Box>
					<Icons.ChevronDown rotate="270" size="3" opacity="50" />
				</Inline>
			</Link>
		</Box>
	)
}

function TripSourceCell({ trip }: { trip: ITrip }) {
	const {
		trip_source,
		owner_quote_request,
		reference_id,
		operated_by_connected_tenant,
		trip_source_contact,
	} = trip
	return (
		<Stack gap="px">
			<Box title={trip_source.name}>
				{trip_source.short_name}
				{reference_id ? `-${reference_id}` : null}
				{owner_quote_request ? (
					<Box
						fontSize="sm"
						display="inline"
						verticalAlign="top"
						marginLeft="1"
						color="muted"
						title={`Request from ${owner_quote_request.from.short_name} using ${config.appTitle}`}
					>
						<Icons.LightningBoltSolid />
					</Box>
				) : null}
				{operated_by_connected_tenant ? (
					<Box
						fontSize="sm"
						display="inline"
						verticalAlign="top"
						marginLeft="1"
						color="muted"
						title={`Bought from ${operated_by_connected_tenant.short_name}`}
					>
						<Icons.LightningBolt />
					</Box>
				) : null}
			</Box>
			{trip_source_contact ? (
				<Inline alignItems="center" flexWrap="wrap" gap="2" fontSize="sm">
					<Box color="muted">{trip_source_contact.name}</Box>
					<Box display="inlineBlock">
						{joinAttributes([
							trip_source_contact.phone_numbers?.length,
							<PhoneNumber
								value={trip_source_contact.phone_numbers}
								iconOnly
							/>,
						])}
					</Box>
				</Inline>
			) : null}
		</Stack>
	)
}

type TListProps = {
	items: Array<ITrip>
	params?: IFilters
	onChange?: () => void
}

export function NewQueryTableView({ items, onChange }: TListProps) {
	return (
		<Table
			bordered
			responsive
			hover
			headers={["ID", "Contact", "Details", "Team", "Tags", ""]}
			rows={items.map((trip) => {
				const {
					destinations,
					created_by,
					created_at,
					nights: no_of_nights,
					days,
					sales_team,
					tags,
					trip_source_contact,
					no_of_adults,
					no_of_children,
					tourist,
				} = trip
				const start_date = localOrUtcTimestampToLocalDate(
					trip.start_date_local,
					trip.start_date
				)
				const end_date = localOrUtcTimestampToLocalDate(
					trip.end_date_local,
					trip.end_date
				)
				return [
					<Stack gap="px">
						<IDCell trip={trip} />
					</Stack>,
					<Stack gap="px">
						<TripSourceCell trip={trip} />
						{!trip_source_contact && tourist ? (
							<Inline gap="1" fontSize="sm" color="muted">
								<Text>{tourist.name}</Text>
								<Box color="muted">
									<PhoneNumber value={tourist.phone_numbers} iconOnly />
								</Box>
							</Inline>
						) : null}
					</Stack>,
					<Stack>
						<Destinations destinations={destinations} />
						<Box fontSize="sm">
							{joinAttributes(
								<Time value={start_date} />,
								<TimeDuration value={duration(days, "days")}>
									{no_of_nights}N
								</TimeDuration>,
								<Text as="span">
									{no_of_adults}A
									{no_of_children ? `, ${no_of_children}C` : null}
								</Text>,
								isAfter(new Date(), end_date) ? (
									<Text as="span" color="danger">
										<Icons.Info title="Past Dates" />
									</Text>
								) : null
							)}
						</Box>
					</Stack>,
					<Stack gap="px">
						{sales_team && sales_team.length ? (
							<Box>
								<Team members={sales_team} />
							</Box>
						) : (
							<Box>By {created_by.name}</Box>
						)}
						<RelativeTime fontSize="sm" color="muted" timestamp={created_at} />
					</Stack>,
					<Box>
						{tags?.map((t) => (
							<Badge
								key={t.name}
								title={
									t.pivot?.created_at
										? utcTimestampToLocalDateString(t.pivot.created_at)
										: undefined
								}
							>
								{t.name}
							</Badge>
						))}
					</Box>,
					<Box textAlign="right">
						<Dropdown alignRight>
							<Dropdown.Toggle paddingX="4">
								<Icons.DotsVertical />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<NavLink
									to={generatePath("/trips/:tripId/select-quote", {
										tripId: trip.id.toString(),
									})}
								>
									<Icons.Plus /> Create Quote
								</NavLink>
								<EditTagsInDialog
									type="trip"
									tags={tags}
									itemId={trip.id}
									onSuccess={onChange}
								>
									{({ onEdit }) => (
										<Dropdown.MenuItem onClick={onEdit}>
											<Icons.Tags color="muted" />
											<Box display="inlineBlock" marginLeft="2">
												Update Tags
											</Box>
										</Dropdown.MenuItem>
									)}
								</EditTagsInDialog>
								<Dropdown.MenuItemDivider />
								<NavLink
									to={generatePath("/trips/:tripId/cancel", {
										tripId: trip.id.toString(),
									})}
									color="warning"
									anchored
								>
									<Icons.Ban /> Cancel Query
								</NavLink>
							</Dropdown.Menu>
						</Dropdown>
					</Box>,
				]
			})}
		/>
	)
}

export function InProgressQueryTableView({ items, onChange }: TListProps) {
	return (
		<Table
			bordered
			responsive
			hover
			headers={[
				"ID",
				"Contact",
				"Details",
				"Quote",
				"Sales Team",
				"Tags",
				"Follow-up",
				"",
			]}
			alignCols={{ 3: "right" }}
			rows={items.map((trip) => {
				const {
					destinations,
					nights: no_of_nights,
					days,
					latest_given_quote,
					latest_comment,
					given_quotes_count,
					tags,
					sales_team,
					tourist,
					trip_source_contact,
					no_of_adults,
					no_of_children,
					last_follow_up_at,
				} = trip
				const start_date = localOrUtcTimestampToLocalDate(
					trip.start_date_local,
					trip.start_date
				)
				const end_date = localOrUtcTimestampToLocalDate(
					trip.end_date_local,
					trip.end_date
				)
				return [
					<IDCell trip={trip} />,
					<Stack gap="px">
						<TripSourceCell trip={trip} />
						{!trip_source_contact && tourist ? (
							<Inline gap="1" fontSize="sm" color="muted">
								<Text>{tourist.name}</Text>
								<Box color="muted">
									<PhoneNumber value={tourist.phone_numbers} iconOnly />
								</Box>
							</Inline>
						) : null}
					</Stack>,
					<Stack gap="px">
						<Destinations destinations={destinations} />
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								<Time value={start_date} format="DD MMM" />,
								<TimeDuration value={duration(days, "days")}>
									{no_of_nights}N
								</TimeDuration>,
								<Text as="span">
									{no_of_adults}A
									{no_of_children ? `, ${no_of_children}C` : null}
								</Text>,
								isAfter(new Date(), end_date) ? (
									<Box display="inline" color="danger">
										<Icons.Info title="Past Dates" />
									</Box>
								) : null
							)}
						</Box>
					</Stack>,
					<Box>
						{latest_given_quote ? (
							<Tooltip
								content={
									<Stack gap="1">
										<Text>
											Created by {latest_given_quote.created_by.name}.
										</Text>
										<Text fontSize="sm">
											Total {pluralize("quote", given_quotes_count, true)}
										</Text>
									</Stack>
								}
							>
								<Stack gap="px">
									<Box>
										<Money
											amount={latest_given_quote.given_price || 0}
											currency={latest_given_quote.given_currency}
										/>
									</Box>
									<Text fontSize="sm" color="muted">
										{fromNow(
											utcTimestampToLocalDate(latest_given_quote.created_at)
										)}
									</Text>
								</Stack>
							</Tooltip>
						) : (
							<Box title="Quote Created but selling price not set">-</Box>
						)}
					</Box>,
					<Box>
						<Team members={sales_team} />
					</Box>,
					<Box>
						{tags?.length ? (
							<Box>
								{tags.map((t) => (
									<Badge
										key={t.id}
										title={
											t.pivot?.created_at
												? utcTimestampToLocalDateString(t.pivot.created_at)
												: undefined
										}
									>
										{t.name}
									</Badge>
								))}
							</Box>
						) : null}
					</Box>,
					<Stack gap="1">
						{latest_comment ? (
							<Tooltip
								content={
									<Box>
										<Box>{latest_comment.body}</Box>
										<Box marginTop="2">
											{fromNow(
												utcTimestampToLocalDate(latest_comment.created_at)
											)}
											{latest_comment.created_by ? (
												<Box display="inline" as="span">
													<Box marginX="1" as="span">
														by
													</Box>
													{latest_comment.created_by.name}
												</Box>
											) : null}
										</Box>
									</Box>
								}
							>
								<Box
									style={{ maxWidth: "200px" }}
									textOverflow="truncate"
									fontSize="sm"
								>
									<Text>{latest_comment.body}</Text>
								</Box>
							</Tooltip>
						) : null}
						{last_follow_up_at ? (
							<Inline gap="1" alignItems="center">
								{getDiff(
									new Date(),
									utcTimestampToLocalDate(last_follow_up_at),
									"days"
								) > 3 ? (
									<Icons.AttentionSolid
										color="danger"
										title="More than 3 days ago"
									/>
								) : null}
								<RelativeTime
									fontSize="sm"
									color="muted"
									timestamp={last_follow_up_at}
								/>
							</Inline>
						) : null}
					</Stack>,
					<Box textAlign="right">
						<AddCommentInDialog
							commentableId={trip.id}
							commentableType="trips"
							onChange={onChange}
						>
							{({ onAdd }) => (
								<EditTagsInDialog
									type="trip"
									tags={tags}
									itemId={trip.id}
									onSuccess={onChange}
								>
									{({ onEdit }) => (
										<Dropdown alignRight>
											<Dropdown.Toggle paddingX="4">
												<Icons.DotsVertical />
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.MenuItem onClick={onAdd}>
													<Icons.Annotation color="muted" />
													<Box display="inlineBlock" marginLeft="2">
														Add Follow-up
													</Box>
												</Dropdown.MenuItem>
												<Dropdown.MenuItem onClick={onEdit}>
													<Icons.Tags color="muted" />
													<Box display="inlineBlock" marginLeft="2">
														Update Tags
													</Box>
												</Dropdown.MenuItem>
												<Dropdown.MenuItemDivider />
												<NavLink
													to={generatePath("/trips/:tripId/cancel", {
														tripId: trip.id.toString(),
													})}
													color="warning"
													anchored
												>
													<Icons.Ban /> Cancel Query
												</NavLink>
											</Dropdown.Menu>
										</Dropdown>
									)}
								</EditTagsInDialog>
							)}
						</AddCommentInDialog>
					</Box>,
				]
			})}
		/>
	)
}

export function OnHoldTripTableView({ items }: TListProps) {
	return (
		<Table
			bordered
			responsive
			hover
			headers={["ID", "Source", "Details", "Guest", "Package", "Team", "Due"]}
			alignCols={{ 4: "right", 6: "right" }}
			rows={items.map((trip) => {
				const {
					destinations,
					nights: no_of_nights,
					days,
					package_amount,
					due_payments,
					on_hold_at,
					team,
					latest_given_quote,
				} = trip
				const start_date = localOrUtcTimestampToLocalDate(
					trip.start_date_local,
					trip.start_date
				)
				const isPaymentOverdue = due_payments?.some(
					(p) =>
						p.next_due_instalment &&
						isBefore(
							localOrUtcTimestampToLocalDate(
								p.next_due_instalment.due_at_local,
								p.next_due_instalment.due_at
							),
							new Date()
						)
				)
				const packageCurrency = latest_given_quote?.given_currency || "INR"
				return [
					<IDCell trip={trip} />,
					<TripSourceCell trip={trip} />,
					<Stack gap="px">
						<Destinations destinations={destinations} />
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								<Time value={start_date} />,
								<TimeDuration value={duration(days, "days")}>
									{no_of_nights}N
								</TimeDuration>,
								getDiff(new Date(), start_date, "month") === 0 ? (
									<RelativeTime
										color="muted"
										fontSize="xs"
										value={start_date}
									/>
								) : null
							)}
						</Box>
					</Stack>,
					<TripTourist trip={trip} />,
					<Box>
						{package_amount ? (
							<Stack gap="px">
								<Text>
									<Money amount={package_amount} currency={packageCurrency} />
								</Text>
								{on_hold_at ? (
									<Box fontSize="sm" color="muted">
										<RelativeTime timestamp={on_hold_at} />
									</Box>
								) : null}
							</Stack>
						) : (
							<Box title="Quote Created but selling price not set">-</Box>
						)}
					</Box>,
					<Team members={team} />,
					due_payments?.length ? (
						<Link
							to={generatePath("/trips/:tripId/accounting/payments", {
								tripId: trip.id.toString(),
							})}
						>
							<Box
								display="inlineFlex"
								alignItems="center"
								fontWeight="semibold"
								color={isPaymentOverdue ? "danger" : "muted"}
							>
								{isPaymentOverdue ? (
									<Box marginRight="2">
										<Icons.Attention title="Overdue" />
									</Box>
								) : null}
								{due_payments[0].next_due_instalment?.due_at ? (
									<RelativeTime
										minUnit="day"
										timestamp={due_payments[0].next_due_instalment?.due_at}
									/>
								) : null}
							</Box>
						</Link>
					) : (
						<Box color="success">
							<Icons.Ok /> Received
						</Box>
					),
				]
			})}
		/>
	)
}

export function ConvertedTripTableView({ items }: TListProps) {
	return (
		<Table
			bordered
			responsive
			hover
			headers={["ID", "Source", "Details", "Guest", "Package", "Due", "Team"]}
			alignCols={{ 4: "right", 5: "right" }}
			rows={items.map((trip) => {
				const {
					destinations,
					nights: no_of_nights,
					days,
					package_amount,
					converted_at,
					due_payments,
					sales_team,
					due_payments_amount,
					latest_given_quote,
				} = trip
				const start_date = localOrUtcTimestampToLocalDate(
					trip.start_date_local,
					trip.start_date
				)
				const isPaymentOverdue = due_payments?.some(
					(p) =>
						p.next_due_instalment &&
						isBefore(
							localOrUtcTimestampToLocalDate(
								p.next_due_instalment.due_at_local,
								p.next_due_instalment.due_at
							),
							new Date()
						)
				)
				const packageCurrency = latest_given_quote?.given_currency || "INR"
				return [
					<IDCell trip={trip} />,
					<TripSourceCell trip={trip} />,
					<Stack gap="px">
						<Destinations destinations={destinations} />
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								<Time value={start_date} />,
								<TimeDuration value={duration(days, "days")}>
									{no_of_nights}N, {days}D
								</TimeDuration>
							)}
						</Box>
						{getDiff(new Date(), start_date, "month") === 0 ? (
							<Box color="muted" fontSize="xs">
								Starts <RelativeTime value={start_date} />
							</Box>
						) : null}
					</Stack>,
					<Box>
						<TripTourist trip={trip} />
					</Box>,
					<Box>
						{package_amount ? (
							<Stack gap="px">
								<Box>
									<Money amount={package_amount} currency={packageCurrency} />
								</Box>
								{converted_at ? (
									<Box fontSize="sm" color="muted">
										<RelativeTime
											value={utcTimestampToLocalDate(converted_at)}
										/>
									</Box>
								) : null}
							</Stack>
						) : (
							<Box title="Quote Created but selling price not set">-</Box>
						)}
					</Box>,
					due_payments?.length && due_payments_amount ? (
						<Link
							to={generatePath("/trips/:tripId/accounting/payments", {
								tripId: trip.id.toString(),
							})}
						>
							<Box
								display="inlineFlex"
								alignItems="center"
								fontWeight="semibold"
								color={isPaymentOverdue ? "danger" : "muted"}
							>
								{isPaymentOverdue ? (
									<Box marginRight="2">
										<Icons.Attention title="Overdue" />
									</Box>
								) : null}
								<Money
									amount={due_payments_amount}
									currency={packageCurrency}
								/>
							</Box>
						</Link>
					) : (
						<Box color="success">
							<Icons.Ok /> Received
						</Box>
					),
					<Box>
						<Team members={sales_team} />
					</Box>,
				]
			})}
		/>
	)
}

function Team({ members }: { members?: Array<{ id: number; name: string }> }) {
	if (!members?.length) return null
	return (
		<Stack style={{ width: "80px" }} gap="px">
			{collect(members)
				.unique((t) => t.id)
				.toArray()
				.map((t, index) => (
					<Text textOverflow="truncate" key={index} fontSize="sm" color="muted">
						{t.name}
					</Text>
				))}
		</Stack>
	)
}

export function LiveTripTableView({ items }: TListProps) {
	return (
		<Table
			bordered
			responsive
			hover
			headers={[
				"ID",
				"Source",
				"Details",
				"Guest",
				"Package",
				"Due",
				"Ops Team",
			]}
			alignCols={{ 4: "right", 5: "right" }}
			rows={items.map((trip) => {
				const {
					destinations,
					nights: no_of_nights,
					days,
					package_amount,
					operations_team,
					due_payments,
					due_payments_amount,
					latest_given_quote,
				} = trip
				const start_date = localOrUtcTimestampToLocalDate(
					trip.start_date_local,
					trip.start_date
				)
				const onTripDays = getDiff(new Date(), start_date, "days") + 1
				const isPaymentOverdue = due_payments?.some(
					(p) =>
						p.next_due_instalment &&
						isBefore(
							localOrUtcTimestampToLocalDate(
								p.next_due_instalment.due_at_local,
								p.next_due_instalment.due_at
							),
							new Date()
						)
				)
				const packageCurrency = latest_given_quote?.given_currency || "INR"
				return [
					<IDCell trip={trip} />,
					<TripSourceCell trip={trip} />,
					<Stack gap="px">
						<Destinations destinations={destinations} />
						<Box>
							{joinAttributes(
								<Box
									title={`Started on ${start_date.toLocaleString()}`}
									display="inline"
								>
									{onTripDays === 1 ? (
										<Badge success>First Day</Badge>
									) : onTripDays === days ? (
										<Badge warning>Last Day</Badge>
									) : (
										<Badge>{withOrdinalSuffix(onTripDays)} Day</Badge>
									)}
								</Box>,
								<TimeDuration value={duration(days, "days")}>
									{no_of_nights}N, {days}D
								</TimeDuration>
							)}
						</Box>
					</Stack>,
					<Box>
						<TripTourist trip={trip} />
					</Box>,
					<Box>
						{package_amount ? (
							<Money amount={package_amount} currency={packageCurrency} />
						) : (
							<Box title="Quote Created but selling price not set">-</Box>
						)}
					</Box>,
					due_payments?.length && due_payments_amount ? (
						<Link
							to={generatePath("/trips/:tripId/accounting/payments", {
								tripId: trip.id.toString(),
							})}
						>
							<Box
								display="inlineFlex"
								alignItems="center"
								fontWeight="semibold"
								color={isPaymentOverdue ? "danger" : "muted"}
							>
								{isPaymentOverdue ? (
									<Box marginRight="2">
										<Icons.Attention title="Overdue" />
									</Box>
								) : null}
								<Money
									amount={due_payments_amount}
									currency={packageCurrency}
								/>
							</Box>
						</Link>
					) : (
						<Box color="success">
							<Icons.Ok /> Received
						</Box>
					),
					<Box>
						{operations_team
							? operations_team.map((t) => t.name).join(", ")
							: null}
					</Box>,
				]
			})}
		/>
	)
}

export function PastTripTableView({ items }: TListProps) {
	return (
		<Table
			bordered
			responsive
			hover
			headers={["ID", "Source", "Details", "Guest", "Package", "Due"]}
			alignCols={{ 4: "right", 5: "right" }}
			rows={items.map((trip) => {
				const {
					start_date,
					destinations,
					nights: no_of_nights,
					days,
					package_amount,
					due_payments,
					due_payments_amount,
					latest_given_quote,
				} = trip
				const isPaymentOverdue = due_payments?.some(
					(p) =>
						p.next_due_instalment &&
						isBefore(
							localOrUtcTimestampToLocalDate(
								p.next_due_instalment.due_at,
								p.next_due_instalment.due_at
							),
							new Date()
						)
				)
				const packageCurrency = latest_given_quote?.given_currency || "INR"
				return [
					<IDCell trip={trip} />,
					<TripSourceCell trip={trip} />,
					<Stack gap="px">
						<Destinations destinations={destinations} />
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								<Time value={start_date} format="D MMM" />,
								<TimeDuration value={duration(days, "days")}>
									{no_of_nights}N, {days}D
								</TimeDuration>
							)}
						</Box>
					</Stack>,
					<Box>
						<TripTourist trip={trip} />
					</Box>,
					<Box>
						{package_amount ? (
							<Money amount={package_amount} currency={packageCurrency} />
						) : (
							<Box title="Quote Created but selling price not set">-</Box>
						)}
						<Text color="muted" fontSize="sm">
							{packageCurrency}
						</Text>
					</Box>,
					due_payments?.length && due_payments_amount ? (
						<Link
							to={generatePath("/trips/:tripId/accounting/payments", {
								tripId: trip.id.toString(),
							})}
						>
							<Box
								display="inlineFlex"
								alignItems="center"
								fontWeight="semibold"
								color={isPaymentOverdue ? "danger" : "muted"}
							>
								{isPaymentOverdue ? (
									<Box marginRight="2">
										<Icons.Attention title="Overdue" />
									</Box>
								) : null}
								<Money
									amount={due_payments_amount}
									currency={packageCurrency}
								/>
							</Box>
						</Link>
					) : (
						<Box color="success">
							<Icons.Ok /> Received
						</Box>
					),
				]
			})}
		/>
	)
}

function CanceledTripsTableView({ items, onChange }: TListProps) {
	return (
		<Table
			bordered
			responsive
			hover
			headers={["ID", "Source", "Details", "Guest", "Quote", "Reason", ""]}
			rows={items.map((trip) => {
				const {
					start_date,
					destinations,
					nights: no_of_nights,
					days,
					cancellation_reason,
					latest_given_quote,
					given_quotes_count,
				} = trip
				return [
					<IDCell trip={trip} />,
					<TripSourceCell trip={trip} />,
					<Stack gap="px">
						<Destinations destinations={destinations} />
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								<Time value={start_date} />,
								<TimeDuration value={duration(days, "days")}>
									{no_of_nights}N
								</TimeDuration>
							)}
						</Box>
					</Stack>,
					<TripTourist trip={trip} />,
					latest_given_quote ? (
						<Box title={`${given_quotes_count} Quotes`}>
							<Money
								amount={latest_given_quote.given_price}
								currency={latest_given_quote.given_currency}
							/>
						</Box>
					) : (
						<Box title="Quote Created but selling price not set">-</Box>
					),
					cancellation_reason ? (
						<Stack gap="px">
							<Box color="warning">{cancellation_reason.reason}</Box>
							<Text fontSize="sm" color="muted">
								{joinAttributes(
									cancellation_reason.created_by?.name,
									cancellation_reason.created_at ? (
										<RelativeTime
											value={utcTimestampToLocalDate(
												cancellation_reason.created_at
											)}
										/>
									) : null
								)}
							</Text>
						</Stack>
					) : null,
					<Box textAlign="right">
						<RevertTripCancellationInDialog
							trip={trip}
							onSuccess={() => onChange?.()}
						>
							{({ onCancel }) => (
								<Button
									level="tertiary"
									size="sm"
									onClick={onCancel}
									title="Revert Cancellation"
								>
									<Icons.Reply />
								</Button>
							)}
						</RevertTripCancellationInDialog>
					</Box>,
				]
			})}
		/>
	)
}

function DroppedTripsTableView({ items }: TListProps) {
	return (
		<Table
			bordered
			responsive
			hover
			headers={[
				"ID",
				"Guest Details",
				"Reason",
				"Package",
				"Charges",
				"Refund",
			]}
			alignCols={{ 3: "right", 4: "right", 5: "right" }}
			rows={items.map((trip) => {
				const {
					start_date,
					destinations,
					nights: no_of_nights,
					days,
					cancellation_reason,
					latest_given_quote,
					given_quotes_count,
					cancellation_charges,
					refunding_payments_amount,
					paid_refunding_payments_amount,
					paid_payments_amount,
				} = trip
				const currency = latest_given_quote?.given_currency || "INR"
				return [
					<Box>
						<IDCell trip={trip} />
						<Box fontSize="sm">
							<TripSourceCell trip={trip} />
						</Box>
					</Box>,
					<Box>
						<TripTourist trip={trip} />
						<Box fontSize="sm">
							{joinAttributes(
								destinations.map((l) => l.short_name).join(", "),
								<Time value={start_date} />,
								<TimeDuration value={duration(days, "days")}>
									{no_of_nights}N, {days}D
								</TimeDuration>
							)}
						</Box>
					</Box>,
					cancellation_reason ? (
						<Stack gap="px">
							<Box color="warning">{cancellation_reason.reason}</Box>
							<Box fontSize="sm" color="muted">
								{joinAttributes(
									cancellation_reason.created_by?.name,
									cancellation_reason.created_at ? (
										<RelativeTime
											value={utcTimestampToLocalDate(
												cancellation_reason.created_at
											)}
										/>
									) : null
								)}
							</Box>
						</Stack>
					) : null,
					<Box>
						{latest_given_quote ? (
							<Box title={`${given_quotes_count} Quotes`}>
								<Money
									amount={latest_given_quote.given_price}
									currency={currency}
									showCurrency
								/>
							</Box>
						) : (
							<Box title="Quote Created but selling price not set">-</Box>
						)}
						{paid_payments_amount ? (
							<Box color="success" marginTop="1" fontSize="sm" title="Paid">
								<Icons.Ok />{" "}
								<Money amount={paid_payments_amount} currency={currency} />
							</Box>
						) : null}
					</Box>,
					cancellation_charges ? (
						<Box>
							<Money amount={cancellation_charges} currency={currency} />
						</Box>
					) : null,
					refunding_payments_amount ? (
						<Box>
							<Box>
								<Money amount={refunding_payments_amount} currency={currency} />
							</Box>
							{paid_refunding_payments_amount ? (
								<Box color="success" marginTop="1" fontSize="sm">
									{paid_refunding_payments_amount ===
									refunding_payments_amount ? (
										<Box>
											<Badge success>Refunded</Badge>
										</Box>
									) : (
										<Box>
											<Icons.Ok />{" "}
											<Money
												amount={paid_refunding_payments_amount}
												currency={currency}
											/>
										</Box>
									)}
								</Box>
							) : null}
						</Box>
					) : null,
				]
			})}
		/>
	)
}

export function AllQueryTableView({ items }: TListProps) {
	return (
		<Table
			bordered
			responsive
			hover
			headers={["ID", "Guest", "Source", "Destination", "Quote", "Team"]}
			rows={items.map((trip) => {
				const {
					destinations,
					nights: no_of_nights,
					days,
					is_end_date_passed,
					cancellation_reason,
					is_live,
					converted_at,
					on_hold_at,
					latest_given_quote,
					given_quotes_count,
					team,
				} = trip
				const start_date = localOrUtcTimestampToLocalDate(
					trip.start_date_local,
					trip.start_date
				)
				return [
					<Stack gap="px">
						<Box>
							<IDCell trip={trip} />
						</Box>
						<Box fontSize="sm">
							{cancellation_reason ? (
								<Badge warning outlined title={cancellation_reason.reason}>
									Canceled
								</Badge>
							) : converted_at ? (
								is_live ? (
									<Box color="accent">
										<Ping title="On Trip" /> On Trip
									</Box>
								) : is_end_date_passed ? (
									<Box color="muted">Past Trip</Box>
								) : (
									<Badge success>Converted</Badge>
								)
							) : on_hold_at ? (
								<Badge success outlined>
									On-Hold
								</Badge>
							) : latest_given_quote ? (
								<Box color="primary">In Progress</Box>
							) : (
								<Box color="muted">New Query</Box>
							)}
						</Box>
					</Stack>,
					<TripTourist trip={trip} />,
					<TripSourceCell trip={trip} />,
					<Stack gap="px">
						<Destinations destinations={destinations} />
						<Box fontSize="sm" color="muted">
							{joinAttributes(
								<Time value={start_date} />,
								<TimeDuration value={duration(days, "days")}>
									{no_of_nights}N
								</TimeDuration>
							)}
						</Box>
					</Stack>,
					<Box
						title={
							latest_given_quote
								? `Latest quote ${fromNow(
										utcTimestampToLocalDate(latest_given_quote.created_at)
									)}`
								: undefined
						}
					>
						{given_quotes_count}
					</Box>,
					team?.length ? <Team members={team} /> : null,
				]
			})}
		/>
	)
}

export function CardsView({ items, params }: TListProps) {
	const { hasPermission } = useCheckPermissions()
	return (
		<Box as="ol">
			{items.map((trip) => {
				const {
					id,
					destinations,
					contact,
					created_by,
					latest_given_quote,
					converted_at,
					nights: no_of_nights,
					days,
					owner_quote_request,
					trip_source,
					latest_comment,
					tags,
				} = trip
				const start_date = localOrUtcTimestampToLocalDate(
					trip.start_date_local,
					trip.start_date
				)
				const end_date = localOrUtcTimestampToLocalDate(
					trip.end_date_local,
					trip.end_date
				)
				return (
					<Box
						as="li"
						key={id}
						bgColor="default"
						rounded="lg"
						overflow="hidden"
						marginBottom="4"
						boxShadow="base"
					>
						<Box
							display="flex"
							justifyContent="between"
							paddingX="4"
							paddingTop="4"
							paddingBottom="2"
						>
							<Box>
								{owner_quote_request ? (
									<Box
										fontSize="sm"
										marginBottom="1"
										borderBottomWidth="1"
										color="success"
										display="inlineBlock"
										title={`Requested by ${owner_quote_request.from.name}`}
									>
										<Icons.LightningBoltSolid />{" "}
										{owner_quote_request.from.short_name}
									</Box>
								) : null}
								<Box />
								<Stack gap="1">
									<Inline gap="4" alignItems="baseline">
										<Link
											to={generatePath("/trips/:tripId", {
												tripId: id.toString(),
											})}
											state={{ params }}
											fontWeight="semibold"
											color="accent"
											fontSize="md"
										>
											{destinations.map((l) => l.name).join(" • ")}
										</Link>
										<Box fontSize="sm" color="muted">
											{joinAttributes(`#${id}`, trip_source.short_name)}
										</Box>
									</Inline>
									<Inline alignItems="center" gap="2">
										<Box>
											<Icons.User />
										</Box>
										<Box>
											{joinAttributes(
												<Text as="span" fontWeight="semibold">
													{contact.name}
												</Text>,
												[
													contact.phone_numbers.length,
													<PhoneNumber
														iconOnly
														value={contact.phone_numbers}
													/>,
												],
												[
													contact.email,
													<Email iconOnly value={contact.email} />,
												]
											)}
										</Box>
									</Inline>
									<Inline gap="2">
										<Box>
											<Icons.Calendar />
										</Box>
										<Stack gap="px">
											<Box>
												{joinAttributes(
													<Time value={start_date} />,
													<TimeDuration value={duration(days, "days")}>
														{`${no_of_nights}N, ${days}D`}
													</TimeDuration>
												)}
											</Box>
											{converted_at ? (
												<Text fontSize="xs" color="muted">
													{isBefore(new Date(), start_date, "second")
														? `${
																getDiff(start_date, new Date(), "days") === 0
																	? fromNow(start_date)
																	: pluralize(
																			"day",
																			getDiff(start_date, new Date(), "days"),
																			true
																		) + " remaining"
															}`
														: isAfter(new Date(), end_date)
															? fromNow(end_date)
															: "On Trip"}
												</Text>
											) : null}
										</Stack>
									</Inline>
								</Stack>
							</Box>
							<Box textAlign="right">
								{tags?.length ? (
									<Stack gap="px" alignItems="end">
										{tags.map((t) => (
											<Box key={t.name}>
												<Badge outlined>{t.name}</Badge>
											</Box>
										))}
									</Stack>
								) : null}
								{trip.cancellation_reason ? (
									<Box>
										<Badge
											title={trip.cancellation_reason.comments}
											warning={!trip.converted_at}
											danger={Boolean(trip.converted_at)}
										>
											{trip.cancellation_reason.reason}
										</Badge>
										<Box fontSize="sm" color="muted">
											<Box>{trip.cancellation_reason.created_by.name}</Box>
											<RelativeTime
												fontSize="xs"
												value={utcTimestampToLocalDate(
													trip.cancellation_reason.created_at
												)}
											/>
										</Box>
									</Box>
								) : latest_given_quote ? null : (
									<Box>
										<Box>Initiated</Box>
										<Box color="muted" fontSize="sm">
											<Box>{created_by.name}</Box>
											<RelativeTime
												fontSize="xs"
												value={utcTimestampToLocalDate(trip.created_at)}
											/>
										</Box>
									</Box>
								)}
							</Box>
						</Box>
						<Stack paddingX="4" paddingY="2" gap="1" borderTopWidth="1">
							<Inline alignItems="center">
								{latest_given_quote
									? joinAttributes(
											<Money
												fontWeight="semibold"
												amount={
													converted_at
														? trip.package_amount
														: latest_given_quote.given_price
												}
												currency={latest_given_quote.given_currency}
												showCurrency
											/>,
											trip.converted_by
												? trip.converted_by.name
												: hasPermission(PERMISSIONS.MANAGE_TRIP_OWNERS)
													? latest_given_quote.created_by.name
													: null,
											<RelativeTime
												fontSize="xs"
												display="inline"
												timestamp={
													converted_at
														? converted_at
														: latest_given_quote.created_at
												}
											/>
										)
									: null}
							</Inline>
							{latest_comment ? (
								<Inline gap="2">
									<Box>
										<Icons.Annotation />
									</Box>
									<Stack textOverflow="truncate" flex="1" minWidth="0">
										<Text>{latest_comment.body}</Text>
										<Box fontSize="xs" color="muted">
											{joinAttributes(
												<RelativeTime timestamp={latest_comment.created_at} />,
												latest_comment.created_by
													? `by ${latest_comment.created_by.name}`
													: null
											)}
										</Box>
									</Stack>
								</Inline>
							) : null}
						</Stack>
					</Box>
				)
			})}
		</Box>
	)
}

export function SelectTrips(
	props: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch">
) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			optionRenderer={({ option }: { option: ITrip }) => (
				<Box>
					<Box>
						{joinAttributes(
							option.id,
							option.destinations.map((l) => l.short_name).join(", "),
							<Time
								value={localOrUtcTimestampToLocalDate(
									option.start_date_local,
									option.start_date
								)}
							/>,
							<TimeDuration value={duration(option.days, "days")}>
								{pluralize("day", option.days, true)}
							</TimeDuration>
						)}
					</Box>
					<Box fontSize="sm" color="muted">
						{joinAttributes(
							`${option.trip_source.short_name}${
								option.reference_id ? `-${option.reference_id}` : ""
							}`,
							option.contact.name
						)}
					</Box>
				</Box>
			)}
			{...props}
			fetch={(q) =>
				tripListXHR(xhr)
					.getTrips({ q })
					.then((resp) => resp.data)
			}
		/>
	)
}

function TripTourist({ trip }: { trip: ITrip }) {
	const { tourist, no_of_adults, no_of_children } = trip
	return tourist ? (
		<Stack gap="px">
			<Box>{tourist.name}</Box>
			<Box fontSize="sm" color="muted">
				{joinAttributes(
					<Text as="span">
						{no_of_adults}A{no_of_children ? `, ${no_of_children}C` : null}
					</Text>,
					[
						tourist.phone_numbers?.length,
						<PhoneNumber value={tourist.phone_numbers} iconOnly />,
					],
					[tourist.email, <Email value={tourist.email} iconOnly />]
				)}
			</Box>
		</Stack>
	) : null
}

function Destinations({
	destinations,
}: {
	destinations: ITrip["destinations"]
}) {
	return (
		<Text style={{ maxWidth: "140px" }} textOverflow="truncate">
			{destinations.map((d) => d.name).join(", ")}
		</Text>
	)
}

export function SelectTripOwners({
	teamType,
	...props
}: Omit<React.ComponentProps<typeof AsyncSelect>, "fetch"> & {
	teamType?: "sales" | "operations"
}) {
	const xhr = useXHR()
	return (
		<AsyncSelect
			{...props}
			fetch={(q) =>
				xhr
					.get("/trip-owners", {
						params: {
							q,
							team_type:
								teamType === "sales" ? 1 : teamType === "operations" ? 2 : null,
						},
					})
					.then((resp) => resp.data.data)
			}
		/>
	)
}
